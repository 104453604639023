$(document).ready(function() {
    var currentDomain = document.domain;
    var sandboxOrStagingPig = (currentDomain.indexOf("salesforce.com") > -1) || (currentDomain.indexOf("demandware.net") > -1);
    if( (currentDomain !== 'store.medicalert.org') || (currentDomain !== 'www.medicalert.org') ){
        $("li.maf-store-links a, #footercontent a.maf-store-link").each(function() {
            var currentDomain = document.domain;
            var currentLinkDomain = $(this).prop('href', this).prop('hostname');
            var currentSlug = this.pathname;
            var newURL = "";
            if( currentLinkDomain !== 'store.medicalert.org'){   
                if (sandboxOrStagingPig){             
                    newURL = "https://"+currentDomain+"/s/medicalert"+currentSlug;
                } else{
                    newURL = "https://"+currentDomain+currentSlug;    
                }
                $(this).attr("href", newURL);
            }
        });        
    }
});
